<template>
  <a-modal
    v-model:visible="showAddModelTypeModal"
    closable
    :title="`${modelTypeToBeEdited ? 'Update' : 'Add'} Model Type`"
    destroy-on-close
    :ok-text="modelTypeToBeEdited ? 'Update' : 'Add'"
    width="60vw"
    :body-style="{
      height: '60vh',
      display: 'flex',
      flexDirection: 'column',
    }"
    :ok-button-props="{ disabled: isDisabled, loading: isAddingModelType }"
    @ok="modelTypeToBeEdited ? handleEditModelType() : handleAddModelType()"
    @cancel="handleCancel"
  >
    <a-input v-model:value="modelType" placeholder="Enter Model Type" />

    <a-list :data-source="modelFiles" class="files-list" bordered size="small">
      <template #header>
        <div class="d-flex align-items-center">
          <a-typography-text strong>
            Model Files
          </a-typography-text>
          <a-button
            class="ml-auto"
            type="primary"
            size="small"
            @click="handleAddNewFile"
          >
            Add File
          </a-button>
        </div>
      </template>

      <template #renderItem="{ item, index }">
        <a-list-item v-if="index === 0" class="list-item">
          <a-typography-text strong content="File Name" />
          <a-typography-text strong content="File Path" />
        </a-list-item>
        <a-list-item class="list-item">
          <template #actions>
            <a-button
              type="link"
              :class="{ 'text-secondary': item.isEdit }"
              default
              @click="handleEdit(item)"
            >
              {{ item.isEdit ? 'Cancel' : 'Edit' }}
            </a-button>

            <a-button
              type="link"
              :class="{ 'text-danger': !item.isEdit }"
              @click="handleSaveRemove(item.file_name)"
            >
              {{ item.isEdit ? 'Save' : 'Remove' }}
            </a-button>
          </template>

          <div>
            <a-input
              v-if="item.isEdit"
              v-model:value="item.file_name"
              size="small"
              placeholder="Enter File Name"
            />
            <a-typography-text v-else :content="item.file_name" />
          </div>

          <div>
            <a-input
              v-if="item.isEdit"
              v-model:value="item.file_path"
              size="small"
              placeholder="Enter File Path"
            />
            <a-typography-text v-else :content="'./' + item.file_path" />
          </div>
        </a-list-item>
      </template>
    </a-list>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      modelType: '',
      modelFiles: [],
      isAddingNewFile: false,
    };
  },

  computed: {
    ...mapGetters(['isAddingModelType', 'modelTypeToBeEdited']),
    showAddModelTypeModal: {
      get() {
        return this.$store.state.modelTypes.showAddModelTypeModal;
      },
      set(value) {
        this.setShowAddModelTypeModal(value);
      },
    },
    isDisabled() {
      if (this.modelTypeToBeEdited) {
        const { name, model_files } = this.modelTypeToBeEdited;
        return (
          this.modelType === name &&
          JSON.stringify(this.modelFiles) === JSON.stringify(model_files)
        );
      }
      return (
        !this.modelType ||
        this.modelFiles.every((f) => !f.file_name && !f.file_path)
      );
    },
  },

  watch: {
    modelTypeToBeEdited(v) {
      if (v) {
        const { name, model_files } = v;
        this.modelType = name;
        this.modelFiles = model_files;
      } else {
        this.modelType = '';
        this.modelFiles = [];
      }
    },
  },

  methods: {
    ...mapActions([
      'setShowAddModelTypeModal',
      'addNewModelTypeAndFiles',
      'setModelToBeEdited',
      'updateModelType',
    ]),
    handleChange(value, fileName) {
      const temp = [...this.modelFiles];
      const fileIndex = temp.findIndex((f) => f.file_name === fileName);
      temp[fileIndex]['file_name'] = value;
      this.modelFiles = temp;
    },

    handleSaveRemove(fileName) {
      let temp = [...this.modelFiles];
      const fileIndex = temp.findIndex((f) => f.file_name === fileName);
      if (temp[fileIndex]['isEdit']) {
        temp[fileIndex]['isEdit'] = false;
      } else {
        temp = temp.filter((f) => f.file_name !== fileName);
      }
      this.modelFiles = temp;
      this.isAddingNewFile = false;
    },

    handleEdit(file) {
      const { file_name } = file;
      const temp = [...this.modelFiles];
      if (this.isAddingNewFile) {
        temp.pop();
        this.isAddingNewFile = false;
      } else {
        const fileIndex = temp.findIndex((f) => f.file_name === file_name);
        temp[fileIndex]['isEdit'] = !temp[fileIndex]['isEdit'];
      }
      this.modelFiles = temp;
    },

    handleAddNewFile() {
      this.isAddingNewFile = true;
      this.modelFiles = [
        ...this.modelFiles,
        { file_name: '', file_path: '', isEdit: true },
      ];
    },

    async handleAddModelType() {
      const modelTypePayload = {
        name: this.modelType,
      };

      await this.addNewModelTypeAndFiles({
        modelTypePayload,
        modelFilesPayload: this.modelFiles,
      });
      this.handleCancel();
    },

    async handleEditModelType() {
      await this.updateModelType({
        newModelType: this.modelType,
        newModelFiles: this.modelFiles,
      });
      this.handleCancel();
    },

    handleCancel() {
      this.modelType = '';
      this.modelFiles = [];
      this.setModelToBeEdited(null);
    },
  },
};
</script>
<style>
.files-list {
  margin-top: 1em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.files-list > .ant-spin-nested-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.files-list > .ant-spin-nested-loading > .ant-spin-container {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.list-item {
  gap: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
