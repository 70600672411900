<template>
  <a-table
    :columns="columns"
    :data-source="modelTypes"
    :loading="isFetchingModelTypes"
  >
    <template #bodyCell="{ record, column }">
      <template v-if="column.key === 'modelFiles'">
        <a-tag
          v-for="modelFile in record.model_files"
          :key="modelFile.file_path"
          color="blue"
        >
          {{ getFilePath(modelFile) }}
        </a-tag>
      </template>
      <template v-if="column.key === 'actions'">
        <a-button @click="handleEditModelType(record)">
          <template #icon>
            <form-outlined />
          </template>
        </a-button>
      </template>
    </template>
  </a-table>
</template>
<script>
import { FormOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import { columns } from './config';

export default {
  components: {
    FormOutlined,
  },

  setup() {
    return {
      columns,
    };
  },

  computed: {
    ...mapGetters(['modelTypes', 'isFetchingModelTypes']),
  },

  created() {
    this.fetchModelTypes();
  },

  methods: {
    ...mapActions(['fetchModelTypes', 'handleEditModelType']),

    getFilePath(file) {
      const { file_name, file_path } = file;
      if (!file_path) return file_name;
      return `${file_path}/${file_name}`;
    },
  },
};
</script>
<style></style>
