export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: 'Model Files',
    dataIndex: 'modelFiles',
    key: 'modelFiles',
    align: 'center',
  },
  {
    title: 'Action(s)',
    key: 'actions',
    align: 'center',
  },
];
